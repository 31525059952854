import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import InputText from "../../../components/form/inputText";
import { alertService, AlertType } from "../../../services/alert.service";
import { APICall } from "../../../services/axiosService";
import { commonService } from "../../../services/common.service";

const DownloadExcelPdfModal = (props) => {
  const [Loading, setLoading] = useState(false);
  const [Input, setInput] = useState({
    email: "",
    errors: {
      email: "",
      ValidationRules: [
        {
          FieldName: "email",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "email",
          ValidationType: "email",
          ValidationMessage: "Invalid email format",
        },
      ],
    },
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = commonService.fnCheckValidationOfObject(Input);
    setInput({
      ...obj.obj,
    });
    if (obj.isValid) {
      setLoading(true);
      props.detail.requestparams.email = Input?.email?.trim();
      if (props.detail.type === "excel") {
        props.detail.requestparams.downloadFileType = "excel";
        let res = await APICall(
          props?.detail?.endpoint,
          props.detail.requestparams
        );
        if (res?.status === 1) {
          alertService.alert({
            type: AlertType.Success,
            message: res?.message,
          });
          setLoading(false);
          props.onHide();
        }
      } else if (props.detail.type === "pdf") {
        props.detail.requestparams.downloadFileType = "pdf";
        let res = await APICall(
          props.detail.endpoint,
          props.detail.requestparams
        );
        if (res?.status === 1) {
          alertService.alert({
            type: AlertType.Success,
            message: res?.message,
          });
          setLoading(false);
          props.onHide();
        }
      }
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.detail?.title}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <InputText
            size={12}
            type={"email"}
            title={"Email"}
            value={Input.email}
            onChange={(value) =>
              setInput({
                ...Input,
                email: value,
              })
            }
            validationText={Input.errors.email}
            // required
          />
        </Modal.Body>
        <Modal.Footer>
          {Loading ? (
            <Button variant="primary">
              <Spinner animation="border" size="sm" /> Loading...
            </Button>
          ) : (
            <Button variant="primary" type="submit">
              Submit
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DownloadExcelPdfModal;
